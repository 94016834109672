import {PatientCartItemModel, PatientCartItemStatus} from '@matchsource/models/cart';

import {ORDER_TYPE} from '@matchsource/models/order';

export const createPatientCartItem = ({
  id,
  type,
  services,
  workupId = null,
  status = PatientCartItemStatus.AvailableToSelect,
  orderType = ORDER_TYPE.CT.type,
  version = undefined,
  isAbonly,
  hasCriticalStatusAnomaly = undefined,
  hasTemporalStatusAnomaly = undefined,
  isStatusAnomalyInitialUser = undefined,
  isSelectDisabled = undefined,
  formCode = undefined,
}: Partial<PatientCartItemModel>): PatientCartItemModel => ({
  id,
  type,
  status,
  services,
  orderType,
  workupId,
  ...(version ? {version} : {}),
  isAbonly,
  hasCriticalStatusAnomaly,
  hasTemporalStatusAnomaly,
  isStatusAnomalyInitialUser,
  isSelectDisabled,
  ...(formCode ? {formCode} : {}),
});
