import {PatientCartItemKey, PatientCartItemModel} from '@matchsource/models/cart';
import {ensureArray} from '@matchsource/utils';
import {SuggestedTypingsCriteria} from '@matchsource/api-generated/orders';

export class LoadPatientCart {
  static readonly type = '[Cart] Load patient cart';

  constructor(public readonly patientId: MsApp.Guid) {}
}

export class SavePatientCart {
  static readonly type = '[Cart] Save patient cart';

  constructor(
    public readonly patientId: MsApp.Guid,
    public readonly items: PatientCartItemModel[]
  ) {}
}

export class RemoveSourcesFromPatientCart {
  static readonly type = '[Cart] Remove source(s) from patient cart';

  readonly sourceIds: MsApp.Guid[];

  constructor(
    public readonly patientId: MsApp.Guid,
    sourceIds: MsApp.Guid | MsApp.Guid[]
  ) {
    this.sourceIds = ensureArray(sourceIds);
  }
}

export class AddSourceToPatientCart {
  static readonly type = '[Cart] Add source to patient cart';

  constructor(
    public readonly patientId: MsApp.Guid,
    public readonly id: MsApp.Guid,
    public readonly data: Partial<PatientCartItemModel>
  ) {}
}

export class UpdateSourcesInPatientCart {
  static readonly type = '[Cart] Update sources in patient cart';

  constructor(
    public readonly patientId: MsApp.Guid,
    public sources: (PatientCartItemKey & Partial<PatientCartItemModel>)[]
  ) {}
}

export class GetSuggestedTypings {
  static readonly type = '[Cart] Get suggested typings';

  constructor(
    public readonly guid: string,
    public cartDonors: SuggestedTypingsCriteria
  ) {}
}
