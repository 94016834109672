import {createSelector} from '@ngxs/store';
import {PatientCartItemModel, PatientCartItemStatus} from '@matchsource/models/cart';
import {CartState, CartStateModel} from './cart.state';
import {filterItems, toMap} from '@matchsource/utils';
import {PatientWorkupInProgressModel} from '@matchsource/api/workup';
import {WorkupsInProgressState} from '@matchsource/store/workup';
import {WorkupInProgressModel} from '@matchsource/models/workup';
import {DonorWorkupInProgressState} from '@matchsource/store/donor-workup-in-progress';

export class PatientCartStateSelectors {
  static cart(patientId: MsApp.Guid) {
    return createSelector([CartState], (state: CartStateModel) => state.patients[patientId] || null);
  }

  static safeCart(patientId: MsApp.Guid) {
    return createSelector([this.cart(patientId)], (state: PatientCartItemModel[] | null) => state || []);
  }

  static map(patientId: MsApp.Guid) {
    return createSelector([this.safeCart(patientId)], (items: PatientCartItemModel[]) => toMap(items, 'id'));
  }

  static size(patientId: MsApp.Guid) {
    return createSelector([this.safeCart(patientId)], (items: PatientCartItemModel[] | null) => items.length);
  }

  static sourceIdList(patientId: MsApp.Guid) {
    return createSelector([this.safeCart(patientId)], (items: PatientCartItemModel[]) => items.map(({id}) => id));
  }

  static cartFiltered(patientId: MsApp.Guid, predicates: Partial<PatientCartItemModel>) {
    return createSelector([this.cart(patientId)], (items: PatientCartItemModel[] | null) =>
      filterItems(items, predicates)
    );
  }

  static cords(patientId: MsApp.Guid) {
    return this.cartFiltered(patientId, {type: 'cord'});
  }

  static cordIdList(patientId: MsApp.Guid) {
    return createSelector([this.cords(patientId)], (cords: PatientCartItemModel[]) => cords.map(({id}) => id));
  }

  static donors(patientId: MsApp.Guid) {
    return this.cartFiltered(patientId, {type: 'donor'});
  }

  static donorIdList(patientId: MsApp.Guid) {
    return createSelector([this.donors(patientId)], (donors: PatientCartItemModel[]) => donors.map(({id}) => id));
  }

  static availableToSelect(patientId: MsApp.Guid) {
    return this.cartFiltered(patientId, {status: PatientCartItemStatus.AvailableToSelect});
  }

  static availableToSelectCount(patientId: MsApp.Guid) {
    return createSelector([this.availableToSelect(patientId)], (items: PatientCartItemModel[]) => items.length);
  }

  static hasAvailableToSelect(patientId: MsApp.Guid) {
    return createSelector([this.availableToSelectCount(patientId)], (count: number) => count > 0);
  }

  static availableToSubmit(patientId: MsApp.Guid) {
    return this.cartFiltered(patientId, {status: PatientCartItemStatus.AvailableToSubmit});
  }

  static availableToSubmitCount(patientId: MsApp.Guid) {
    return createSelector([this.availableToSubmit(patientId)], (items: PatientCartItemModel[]) => items.length);
  }

  static hasAvailableToSubmitOrdinaryCount(patientId: MsApp.Guid) {
    return createSelector(
      [this.availableToSubmit(patientId)],
      (items: PatientCartItemModel[]) =>
        items.filter(order => order.status === PatientCartItemStatus.AvailableToSubmit).length
    );
  }

  static hasAvailableToSubmit(patientId: MsApp.Guid) {
    return createSelector([this.availableToSubmitCount(patientId)], (count: number) => count > 0);
  }

  static donorsInProgressList(patientId: MsApp.Guid) {
    return createSelector(
      [WorkupsInProgressState.donors(), DonorWorkupInProgressState.workups, this.availableToSubmit(patientId)],
      (
        workups: WorkupInProgressModel[],
        newWorkups: WorkupInProgressModel[],
        availableToSubmit: PatientCartItemModel[]
      ) => {
        const allWorkups = [...workups, ...newWorkups];
        if (availableToSubmit.length === 0) {
          return allWorkups;
        }

        return allWorkups.filter(item => !availableToSubmit.find(elem => +elem.workupId === +item.id));
      }
    );
  }

  static donorsInProgressIdsList(patientId: MsApp.Guid) {
    return createSelector([this.donorsInProgressList(patientId)], (donors: PatientWorkupInProgressModel[]) =>
      donors.map(({sourceId}) => sourceId)
    );
  }

  static cordsInProgressList(patientId: MsApp.Guid) {
    return createSelector(
      [WorkupsInProgressState.cords(), this.availableToSubmit(patientId)],
      (workups: WorkupInProgressModel[], availableToSubmit: PatientCartItemModel[]) => {
        if (availableToSubmit.length === 0) {
          return workups;
        }

        return workups.filter(item => !availableToSubmit.find(elem => +elem.workupId === +item.id));
      }
    );
  }

  static cordsInProgressIdsList(patientId: MsApp.Guid) {
    return createSelector([this.cordsInProgressList(patientId)], (cords: PatientWorkupInProgressModel[]) =>
      cords.map(cord => cord.sourceId)
    );
  }

  static workupInProgressCount(patientId: MsApp.Guid) {
    return createSelector(
      [this.donorsInProgressList(patientId), this.cordsInProgressList(patientId)],
      (donorList, cordList) => donorList.length + cordList.length
    );
  }
}
