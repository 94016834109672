import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  AddSourceToPatientCart,
  GetSuggestedTypings,
  LoadPatientCart,
  RemoveSourcesFromPatientCart,
  SavePatientCart,
  UpdateSourcesInPatientCart,
} from './cart.actions';
import {CartApiService} from '@matchsource/api/cart';
import {patch} from '@ngxs/store/operators';
import {PatientCartItemModel} from '@matchsource/models/cart';
import {createPatientCartItem} from './utils';
import {toMap} from '@matchsource/utils';
import {OplApiService} from '@matchsource/api/opl';
import {DonorOpl} from '@matchsource/api-generated/orders';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';

export interface CartStateModel {
  patients: {[key: string]: PatientCartItemModel[]};
  suggestedTypings: DonorOpl[];
}

@Injectable()
@State<CartStateModel>({
  name: 'cart',
  defaults: {
    patients: {},
    suggestedTypings: null,
  },
})
@Injectable()
export class CartState {
  constructor(
    private readonly cartApiService: CartApiService,
    private readonly oplApiService: OplApiService
  ) {}

  @Selector([CartState])
  static suggestedTypings(state: CartStateModel): DonorOpl[] {
    return state.suggestedTypings;
  }

  @Action(LoadPatientCart)
  load(ctx: StateContext<CartStateModel>, {patientId}: LoadPatientCart) {
    const patientCart = this.cartApiService.get(patientId);

    ctx.setState(
      patch({
        patients: patch({
          [patientId]: patientCart,
        }),
      })
    );
  }

  @Action(SavePatientCart)
  save(ctx: StateContext<CartStateModel>, {patientId, items}: SavePatientCart) {
    this.cartApiService.save(patientId, items);

    ctx.setState(
      patch({
        patients: patch({
          [patientId]: items,
        }),
      })
    );
  }

  @Action(RemoveSourcesFromPatientCart)
  removeSources(ctx: StateContext<CartStateModel>, {patientId, sourceIds}: RemoveSourcesFromPatientCart) {
    const state = ctx.getState();
    const items = state.patients[patientId] || [];
    const remainItems = items.filter(({id}) => !sourceIds.includes(id));

    return ctx.dispatch(new SavePatientCart(patientId, remainItems));
  }

  @Action(AddSourceToPatientCart)
  addSource(ctx: StateContext<CartStateModel>, {patientId, id, data}: AddSourceToPatientCart) {
    const state = ctx.getState();
    const items = state.patients[patientId] || [];
    if (items.find(elem => elem.id === id)) {
      return;
    }
    const newItems = [...items, createPatientCartItem({...data, id})];
    return ctx.dispatch(new SavePatientCart(patientId, newItems));
  }

  @Action(UpdateSourcesInPatientCart)
  updateSources(ctx: StateContext<CartStateModel>, {patientId, sources}: UpdateSourcesInPatientCart) {
    const sourcesMap = toMap(sources, 'id');
    const state = ctx.getState();
    const items = state.patients[patientId] || [];
    const newItems = items.map(item => {
      if (item.id in sourcesMap) {
        const updatedItem = sourcesMap[item.id];
        return createPatientCartItem({...item, ...updatedItem});
      }

      return item;
    });

    return ctx.dispatch(new SavePatientCart(patientId, newItems));
  }

  @Action(GetSuggestedTypings)
  getSuggestedTypings(ctx: StateContext<CartStateModel>, {guid, cartDonors}: GetSuggestedTypings) {
    return this.oplApiService.getSuggestedTypings(guid, cartDonors).pipe(
      catchError(() => of([])),
      tap(suggestedTypings =>
        ctx.setState(
          patch({
            suggestedTypings,
          })
        )
      )
    );
  }
}
